import React from 'react';
import { useEffect, useState } from 'react';

export const HeaderComponent = () => {
    const [season, setSeason] = useState('');

    useEffect(() => {
        const banners = [
            "winter",
            "winter",
            "spring",
            "spring",
            "spring",
            "summer",
            "summer",
            "summer",
            "summer",
            "summer",
            "winter",
            "winter",
        ];
        const currentDate = new Date();
        const month = currentDate.getMonth();
        const hour = currentDate.getHours();
        setSeason(() => banners[month]);
        if (season === 'summer') {
            if (hour > 19) {
                setSeason(() => 'evening');
            }
        }
    }, []);

    return (
        <div className="container-fluid">
            <div className="row text-center">
                <header id="main_header" className={season}>
                    <img
                        id="header_logo"
                        loading="lazy"
                        src="/assets/ferienwohnungen-suedwestpfalz-white.svg"
                        alt="Ferienwohnungen Südwestpfalz"
                    />
                    <div className="col-md-12 col-xs-12 pt-3">
                        <p className="text-light h3">Ich freu mich schon aufs nächste Mal..</p>
                    </div>
                </header>
            </div>
        </div>
    );
};
